<template>
  <div>
    <MyHeader :title="$t('lang.menu_changepwd')" :ShowBack="true" />
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="txtUserName"
        readonly
        clearable
        :label="$t('lang.username')"
      />
      <van-field
        v-model="password"
        name="txtPassword"
        type="password"
        clearable
        :label="$t('lang.oldpwd')"
        :placeholder="$t('lang.oldpwd_input')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="password1"
        name="txtPassword1"
        type="password"
        clearable
        :label="$t('lang.newpwd')"
        :placeholder="$t('lang.newpwd_input')"
        :rules="[{ required: true }]"
      />
      <van-field
        v-model="password2"
        name="txtPassword2"
        type="password"
        clearable
        :label="$t('lang.renewpwd')"
        :placeholder="$t('lang.renewpwd_input')"
        :rules="[{ required: true }]"
      />
      <div style="margin: 16px">
        <van-button block color="#669999" native-type="submit">
          {{ $t("lang.submit") }}
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import MyHeader from "@/components/Header.vue";
import { ChangePassword } from "@/api/index.js";
import { Dialog } from "vant";
export default {
  components: { MyHeader },
  data() {
    return {
      username: this.$store.state.user.userName,
      password: "",
      password1: "",
      password2: "",
    };
  },
  methods: {
    onSubmit(fr) {
      console.log(fr);
      ChangePassword(
        {
          token: this.$store.state.token,
          openId: this.$store.state.openid,
          userName: fr.txtUserName,
          oldPassword: fr.txtPassword,
          password: fr.txtPassword1,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              Dialog.alert({
                message: this.$t("lang.changepwd_success"), 
              }).then(() => {});
            } else this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.changepwd_success"));
        }
      );
    },
  },
};
</script>
